import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Container } from '../components/home/v2/StyledComponents';
import isMobile from '../services/isMobile';
import MenuBar from '../components/home/MenuBar';
import Footer from '../components/home/Footer';
import OurArtisansHeaderBlock from '../components/OurArtisans/OurArtisansHeaderBlock';
import OurArtisansTopBlock from '../components/OurArtisans/OurArtisansTopBlock';
import OurArtisansMiddleBlock from '../components/OurArtisans/OurArtisansMiddleBlock';
import OurArtisansBottomBlock from '../components/OurArtisans/OurArtisansBottomBlock';
import Layout from '../layouts/index';

const InterviewsTemplate = ({ data: { interviewsJson } }) => (
  <Layout routeSlug={`OurArtisans_${interviewsJson.slug}`}>
    <Container>
      <MenuBar />
      <OurArtisansHeaderBlock
        headerImage={interviewsJson.headerImage.publicURL}
        name={interviewsJson.title}
        nickname={isMobile() ? interviewsJson.nicknameMobile || interviewsJson.nickname : interviewsJson.nickname}
      />
      <OurArtisansTopBlock
        specialtiesTitle={interviewsJson.sectionTitle.specialties}
        image1={interviewsJson.image1.publicURL}
        imageAlt1={interviewsJson.imageAlt1}
        specialties={isMobile()
          ? interviewsJson.specialtiesMobile || interviewsJson.specialties
          : interviewsJson.specialties}
        tilliAndMeTitle={interviewsJson.sectionTitle.tilliAndMe}
        image2={isMobile() ? interviewsJson.image2Mobile.publicURL : interviewsJson.image2.publicURL}
        imageAlt2={interviewsJson.imageAlt2}
        tilliAndMe={interviewsJson.tilliAndMe}
        style={isMobile() ? interviewsJson.style.ourArtisansTopBlockMobile : interviewsJson.style.ourArtisansTopBlock}
      />
      <OurArtisansMiddleBlock
        text={interviewsJson.tillisteWords}
        image={isMobile() ? interviewsJson.image2Mobile.publicURL : interviewsJson.image2.publicURL}
        imageAlt={interviewsJson.imageAlt2}
        style={interviewsJson.style.ourArtisansMiddleBlockMobile}
      />
      <OurArtisansBottomBlock
        title={interviewsJson.sectionTitle.tillisteInterview}
        name={interviewsJson.title}
        image={isMobile() ? interviewsJson.image3Mobile.publicURL : interviewsJson.image3.publicURL}
        imageAlt={interviewsJson.imageAlt3}
        interview={interviewsJson.tillisteInterview}
        style={interviewsJson.style.ourArtisansBottomBlock}
      />
      <Footer />
    </Container>
  </Layout>
);

InterviewsTemplate.propTypes = {
  data: PropTypes.shape({
    interviewsJson: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default InterviewsTemplate;

export const ArtisansPageQuery = graphql`
  query ArtisansTemplateQuery($path: String!) {
    interviewsJson(path: { eq: $path }) {
      slug
      path
      title
      nickname
      nicknameMobile
      headerImage {
        publicURL
      }
      sectionTitle {
        specialties
        tilliAndMe
        tillisteInterview
      }
      image1 {
        publicURL
      }
      imageAlt1
      specialties
      specialtiesMobile
      image2 {
        publicURL
      }
      image2Mobile {
        publicURL
      }
      imageAlt2
      tilliAndMe {
        title
        paragraph
      }
      tillisteWords
      image3 {
        publicURL
      }
      image3Mobile {
        publicURL
      }
      imageAlt3
      tillisteInterview {
        title
        paragraph
      }
      style {
        ourArtisansTopBlock {
          specialtiesTitleMargin
          specialtiesContainerMargin
          tilliAndMeTitleMargin
          tilliAndMeContainerMargin
        }
        ourArtisansTopBlockMobile {
          specialtiesTitleMargin
          specialtiesContainerMargin
        }
        ourArtisansMiddleBlockMobile {
          tillisteWordsMarginTop
        }
        ourArtisansBottomBlock {
          interviewTitleMargin
          interviewContainerMargin
        }
      }
    }
  }
`;
