import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isMobile from '../../services/isMobile';
import Dots from '../home/v2/Dots';
import { toFormattedText } from '../../services/formatting';
import {
  mobileThresholdPixels, colors, margins, fontSizes, Content,
} from '../home/v2/StyledComponents';

const Container = styled.div``;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const TextContainer = styled(Content)`
  background-color: ${colors.lightGrey};
  z-index: 1;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 75%;
    margin: 150px ${margins.l} 0px 0px;
    ${(props) => props.marginTop && `margin-top: ${props.marginTop}`};
  }
`;

const P = styled.p`
  font-family: Roboto;
  font-size: ${fontSizes.xl};
  font-style: italic;
  text-transform: full-width;
  color: ${colors.navy};
  margin: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s};
    font-size: ${fontSizes.l};
  }
`;

const Img = styled.img`
  position: absolute;
  width: 75%;
  right: 15px;
  margin-top: ${margins.xl};
`;

const OurArtisansMiddleBlock = ({
  text, image, imageAlt, style,
}) => (
  <Container>
    {!isMobile()
      ? (
        <SubContainer>
          <Dots big />
          <TextContainer>
            <P>{toFormattedText(text, true)}</P>
          </TextContainer>
          <Dots big />
        </SubContainer>
      )
      : (
        <SubContainer>
          <TextContainer marginTop={style.tillisteWordsMarginTop}>
            <Dots big />
            <P>{toFormattedText(text, true)}</P>
            <Dots big />
          </TextContainer>
          <Img src={image} alt={imageAlt} />
        </SubContainer>
      )}
  </Container>
);

OurArtisansMiddleBlock.propTypes = {
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  style: PropTypes.shape({}).isRequired,
};

export default OurArtisansMiddleBlock;
