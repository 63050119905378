import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import routesMap from '../../Routes';
import isMobile from '../../services/isMobile';
import { toFormattedText, toFormattedColoredText } from '../../services/formatting';
import {
  colors, fontSizes, margins, mobileThresholdPixels, Content, Button,
}
  from '../home/v2/StyledComponents';

const Container = styled.div`
  display: flex;
  padding: ${margins.xl} 0px;
  color: ${colors.navy};
`;

const Img = styled.img`
  width: unset;
  max-height: 600px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    height: 200px;
  }
`;

const SubContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 45%;
  }
`;

const ZoomBlock = styled.div`
  display: flex;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100% - 2*${margins.s});
  }
`;

const TitleContainer = styled.div`
  margin: ${(props) => props.margin};
  z-index: 1;
`;

const Title2 = styled.h2`
  font-family: Gotham;
  font-size: ${fontSizes.x4l};
  text-transform: uppercase;
  margin: ${margins.l} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.xl};
    margin: 0px;
  }
`;

const Title3 = styled.h3`
  font-family: Gotham;
  font-size: ${fontSizes.l};

  @media (max-width: ${mobileThresholdPixels}) {
    font-weight: normal;
  }
`;

const TextBlock = styled.div`
  display: flex;
  z-index: 1;

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: right;
    flex-direction: column;
    align-items: flex-end;
  }
`;

const TextContainer = styled.div`
  width: 440px;
  background-color: ${`${colors.white}F0`};
  margin: ${(props) => props.margin};
  z-index: 1;
  padding: ${margins.xs} ${margins.s} ${margins.s};

  @media (max-width: ${mobileThresholdPixels}) {
    position: relative;
    width: calc(100% - 2*${margins.m});
    margin: 0px;
  }
`;

const SectionContainer = styled.div``;

const P = styled.div`
  font-family: Roboto;
  font-size: ${fontSizes.s};
`;

const StyledButton = styled(Button)`
  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s} 0px;
    padding: 0px;
    font-size: 10px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: ${margins.xl} 0px ${margins.l};

  @media (max-width: ${mobileThresholdPixels}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 2*${margins.s});
    margin: auto;
    margin-top: ${margins.m};
  }
`;

const OurArtisansBottomBlock = ({
  name, title, image, imageAlt, interview, style,
}) => (
  <Container>
    <Content>
      <ZoomBlock>
        {!isMobile()
          && (
            <SubContainer>
              <Img src={image} alt={imageAlt} />
              <TitleContainer margin={style.interviewTitleMargin}>
                <Title2>{toFormattedColoredText(title)}</Title2>
              </TitleContainer>
            </SubContainer>
          )}
        <TextBlock>
          {isMobile() && (
            <Title2>
              Zoom sur
              {name}
            </Title2>
          )}
          <TextContainer margin={style.interviewContainerMargin}>
            {interview.map((section) => (
              <SectionContainer key={section.title}>
                <Title3>{section.title}</Title3>
                <P>{toFormattedText(section.paragraph, true)}</P>
              </SectionContainer>
            ))}
          </TextContainer>
        </TextBlock>
      </ZoomBlock>
      <ButtonContainer>
        {isMobile() && <SubContainer><Img src={image} alt={imageAlt} /></SubContainer>}
        <Link to={routesMap.Step1.url}>
          <StyledButton>réserver votre tilliste</StyledButton>
        </Link>
      </ButtonContainer>
    </Content>
  </Container>
);

OurArtisansBottomBlock.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  interview: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    paragraph: PropTypes.string.isRequired,
  })).isRequired,
  style: PropTypes.shape({}).isRequired,
};

export default OurArtisansBottomBlock;
