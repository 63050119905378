import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isMobile from '../../services/isMobile';
import { toFormattedText, toFormattedColoredText } from '../../services/formatting';
import Dots from '../home/v2/Dots';
import {
  colors, fontSizes, margins, mobileThresholdPixels, Content,
}
  from '../home/v2/StyledComponents';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${margins.xl} 0px;
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: ${margins.l} ${margins.s} 0px;
  }
`;

const Title2 = styled.h2`
  font-family: Gotham;
  font-size: ${fontSizes.x4l};
  text-transform: uppercase;
  margin: ${margins.l} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.xl};
    margin: ${margins.s} 0px;
  }
`;

const Img = styled.img`
  width: unset;
  max-height: ${(props) => props.longer ? '600px' : '460px'};
  min-height: 450px;

  @media (max-width: ${mobileThresholdPixels}) {
    min-height: unset;
    height: 160px;
  }
`;

const SubContainer = styled.div`
  ${(props) => !props.forText ? `
    display: flex;
    justify-content: center;
  ` : `
    z-index: 1;
  `}
`;

const TitleContainer = styled.div`
  margin: ${(props) => props.margin};
  z-index: 1;
`;

const SpecialtiesBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100% - 2*${margins.s});
  }
`;

const SpecialtiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.margin};
`;

const Span1 = styled.span`
  font-size: ${fontSizes.m};
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;

const TilliAndMeBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: ${margins.l};
    width: calc(100% - 2*${margins.s});
  }
`;

const Title3 = styled.h3`
  font-family: Gotham;
  font-size: ${fontSizes.l};

  @media (max-width: ${mobileThresholdPixels}) {
    font-weight: normal;
  }
`;

const TextContainer = styled.div`
  background-color: ${`${colors.white}F0`};
  width: 120%;
  z-index: 2;
  margin: ${(props) => props.margin};
  padding: ${margins.xs} ${margins.m} ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    position: relative;
    width: calc(100% - 2*${margins.m});
    margin-right: 0px;
    padding: 0px 0px 0px ${margins.s};
  }
`;

const SectionContainer = styled.div``;

const P = styled.div`
  font-family: Roboto;
  font-size: ${fontSizes.s};
`;

const OurArtisansTopBlock = ({
  specialtiesTitle, image1, imageAlt1, specialties,
  tilliAndMeTitle, image2, imageAlt2, tilliAndMe, style,
}) => (
  <Container>
    <Content>
      <SpecialtiesBlock>
        <SubContainer>
          <Img src={image1} alt={imageAlt1} />
          <TitleContainer margin={style.specialtiesTitleMargin}>
            <Title2>{toFormattedColoredText(specialtiesTitle)}</Title2>
          </TitleContainer>
        </SubContainer>
        <SubContainer forText>
          <SpecialtiesContainer margin={style.specialtiesContainerMargin}>
            <Dots big />
            <Span1>{toFormattedText(specialties)}</Span1>
          </SpecialtiesContainer>
        </SubContainer>
      </SpecialtiesBlock>
      <TilliAndMeBlock>
        <SubContainer forText>
          <TextContainer margin={style.tilliAndMeContainerMargin}>
            {isMobile() && <Title2>Tilli & Moi</Title2>}
            {tilliAndMe.map((section) => (
              <SectionContainer key={section.title}>
                <Title3>{section.title}</Title3>
                <P>{toFormattedText(section.paragraph, true)}</P>
              </SectionContainer>
            ))}
          </TextContainer>
        </SubContainer>
        {!isMobile()
          && (
            <SubContainer>
              <TitleContainer margin={style.tilliAndMeTitleMargin}>
                <Title2>{toFormattedColoredText(tilliAndMeTitle)}</Title2>
              </TitleContainer>
              <Img src={image2} alt={imageAlt2} longer />
            </SubContainer>
          )}
      </TilliAndMeBlock>
    </Content>
  </Container>
);

OurArtisansTopBlock.propTypes = {
  specialtiesTitle: PropTypes.string.isRequired,
  image1: PropTypes.string.isRequired,
  imageAlt1: PropTypes.string.isRequired,
  specialties: PropTypes.string.isRequired,
  tilliAndMeTitle: PropTypes.string.isRequired,
  image2: PropTypes.string.isRequired,
  imageAlt2: PropTypes.string.isRequired,
  tilliAndMe: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    paragraph: PropTypes.string.isRequired,
  })).isRequired,
  style: PropTypes.shape({}).isRequired,
};

export default OurArtisansTopBlock;
